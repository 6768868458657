import { defineMessages } from 'react-intl'

export const scope = 'qrq.landing'

const messages = defineMessages({
	welcome: {
		id: `${scope}.welcome`
	},
	qrqDescription: {
		id: `${scope}.qrqDescription`
	}
})

export default messages
