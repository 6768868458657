import React from 'react'
import { PropTypes } from 'prop-types'
import { Divider, Image, Stack, Text } from '@chakra-ui/core'
import { injectIntl } from 'react-intl'
import AppContainer from '../ui/containers/AppContainer'
import { Title } from '../ui/text'
import messages from './messages'

const Landing = ({ intl: { formatMessage } }) => {
	return (
		<>
			<AppContainer.Header>
				<Title textAlign='center'>{formatMessage(messages.welcome)}</Title>
			</AppContainer.Header>
			<Divider />
			<AppContainer.Content>
				<Stack h='100%' align='center' justify='center' spacing={4}>
					<Image src='/images/read-qr.svg' alt='Read QR-Code' ignoreFallback={true} />
					<Text textAlign='center'>{formatMessage(messages.qrqDescription)}</Text>
				</Stack>
			</AppContainer.Content>
		</>
	)
}

Landing.propTypes = {
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
}

export default injectIntl(Landing)
