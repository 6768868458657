import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Button } from '@chakra-ui/core'
import {
	hasUserConsented,
	NotificationConsentDeniedError,
	requestNotificationPermission,
	turnOnNotifications
} from './pushNotifications'
import { MediumText } from '../ui/text'
import messages from './messages'
import BulletState from '../ui/bulletState/BulletState'
import InlineStack from '../ui/stacks/InlineStack'
import api from '../api/api'
import { interceptError } from '../utils/errors/errorInterceptors'
import { showErrorModal } from '../utils/errors/showUIErrors'

const NotificationsWidget = ({ clientId, intl: { formatMessage } }) => {
	const [show, setShow] = useState(false)
	const [isLoading, setLoading] = useState(true)
	const [activeNotifications, setActiveNotifications] = useState(false)
	const [userHasConsent, setUserHasConsent] = useState(hasUserConsented())

	const onClickNotificationsButton = () => {
		setLoading(true)
		requestNotificationPermission()
			.then(() => setUserHasConsent(hasUserConsented()))
			.catch(e => {
				if (e === NotificationConsentDeniedError) setShow(false)
			})
	}

	useEffect(() => {
		if (userHasConsent) {
			turnOnNotifications()
				.then(subscription =>
					api.notifications
						.subscribe(clientId, subscription)
						.then(() => {
							setActiveNotifications(true)
							setShow(true)
						})
						.catch(interceptError)
				)
				.catch(() => {
					showErrorModal([formatMessage(messages.notificationsError)])
					setShow(false)
				})
				.finally(() => {
					setLoading(false)
				})
		} else {
			setLoading(false)
			setShow(true)
		}
	}, [clientId, userHasConsent, formatMessage])

	const component = show ? (
		activeNotifications ? (
			<InlineStack>
				<BulletState state='on' />
				<MediumText my={4} color='green.500'>
					{formatMessage(messages.notificationsActive)}
				</MediumText>
			</InlineStack>
		) : (
			<Button
				isLoading={isLoading}
				isDisabled={isLoading}
				onClick={onClickNotificationsButton}
				rightIcon='notifications'
				fontWeight='normal'
				variantColor='gray'
			>
				<MediumText>{formatMessage(messages.turnOnNotifications)}</MediumText>
			</Button>
		)
	) : null

	return component
}

NotificationsWidget.propTypes = {
	clientId: PropTypes.string.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	})
}

export default injectIntl(NotificationsWidget)
