import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'

import AppContainer from './ui/containers/AppContainer'
import { ConfirmProvider } from './utils/confirm/ConfirmProvider'
import Footer from './footer/Footer'
import { ModalWrapper, modalManager } from './utils/modals/ModalWrapper'
import NotFound from './notFound/NotFound'
import Landing from './landing/Landing'
import LocationContainer from './location/LocationContainer'
import TicketContainer from './ticket/containers/TicketContainer'
import MQTTProvider from './mqtt/MQTTProvider'
import errorsMessages from './utils/errors/messages'
import modalsMessages from './utils/modals/messages'

const App = ({ intl: { formatMessage } }) => {
	const mqttConnection = {
		port: process.env.REACT_APP_MQTT_PORT,
		host: process.env.REACT_APP_MQTT_PATH,
		username: '',
		protocol: process.env.REACT_APP_MQTT_PROTOCOL,
		clientId: `public_${Math.random()
			.toString(16)
			.substr(2, 8)}`
	}

	return (
		<AppContainer>
			<ModalWrapper />
			<MQTTProvider
				connection={mqttConnection}
				onConnectionError={() =>
					modalManager.showModal(formatMessage(modalsMessages.error), [formatMessage(errorsMessages.mqttErrorMessage)])
				}
			>
				<ConfirmProvider>
					<Switch>
						<Route exact path='/:locationId([0-9]+)\::hash([a-zA-Z0-9_-]+)' component={LocationContainer} />
						<Route exact path='/:locationId([0-9]+)/ticket' component={TicketContainer} />
						<Route exact path='/' component={Landing} />
						<Route path='*' component={NotFound} />
					</Switch>
				</ConfirmProvider>
			</MQTTProvider>
			<AppContainer.Footer>
				<Footer />
			</AppContainer.Footer>
		</AppContainer>
	)
}

App.propTypes = {
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
}

export default injectIntl(withRouter(App))
