import { theme as chakraTheme } from '@chakra-ui/core'
import icons from './icons'

const colors = {
	...chakraTheme.colors,
	gray: {
		50: '#f9f9fa',
		100: '#ededf0',
		200: '#e0e0e5',
		300: '#d3d2d9',
		400: '#c3c3cc',
		500: '#b2b2bd',
		600: '#9f9ead',
		700: '#888799',
		800: '#6b6a80',
		900: '#3e3e50'
	},
	black: '#424242',
	brand: {
		red: '#CF0A2C',
		green: '#3EC28F',
		deepBlue: '#0D0CB5',
		activeBlue: '#005BEA',
		purple: '#4E2F60',
		lightGray: '#F3F3F3',
		gray: '#B8B8B8'
	},
	blue: {
		50: '#73A5F3',
		100: '#5C96F1',
		200: '#4587EF',
		300: '#2E78ED',
		400: '#1769EB',
		500: '#005BEA',
		600: '#0053D5',
		700: '#004BC0',
		800: '#0043AB',
		900: '#003A95'
	},
	primary: {
		50: '#e6f7e9',
		100: '#c3eac8',
		200: '#9bdda5',
		300: '#6ed17f',
		400: '#47c662',
		500: '#05bb45',
		600: '#00ab3b',
		700: '#00992f',
		800: '#008823',
		900: '#006909'
	},
	secondary: {
		50: '#e1f1f1',
		100: '#b3dedc',
		200: '#83c9c6',
		300: '#53b3ae',
		400: '#33a29d',
		500: '#20928a',
		600: '#1d857d',
		700: '#1b756d',
		800: '#19655e',
		900: '#154941'
	}
}

const bulletState = {
	colors: {
		on: colors.brand.green,
		away: colors.yellow[400],
		off: colors.brand.red
	}
}

const fontSizes = {
	...chakraTheme.fontSizes,
	'7xl': '84px',
	'2xs': '0.625rem'
}

const chakraIcons = {
	...icons,
	'triangle-down': chakraTheme.icons['triangle-down'],
	'triangle-up': chakraTheme.icons['triangle-up'],
	'question-outline': chakraTheme.icons['question-outline']
}

const theme = {
	...chakraTheme,
	colors: {
		...colors,
		bulletState: bulletState.colors
	},
	fontSizes,
	icons: chakraIcons,
	fonts: {
		body: 'Roboto, sans-serif',
		heading: 'Roboto, serif'
	}
}

export default theme
