import { defineMessages } from 'react-intl'

export const scope = 'qrq.notifications'

const messages = defineMessages({
	turnOnNotifications: {
		id: `${scope}.turnOnNotifications`
	},
	notificationsActive: {
		id: `${scope}.notificationsActive`
	},
	notificationsError: {
		id: `${scope}.notificationsError`
	}
})

export default messages
