import React from 'react'
import PropTypes from 'prop-types'
import { Stack, ButtonGroup } from '@chakra-ui/core'
import { useIntl } from 'react-intl'
import InlineStack from '../../ui/stacks/InlineStack'
import Button from '../../buttons/Button'
import AddMorePeopleButton from './AddMorePeopleButton'
import { MediumText } from '../../ui/text'
import messages from '../messages'

const PeopleNumberSelector = ({ value, defaultValues, onChange, ...props }) => {
	const { formatMessage } = useIntl()
	const buttonValues = [...new Set(defaultValues).add(value)]

	return (
		<Stack align='center' {...props}>
			<MediumText fontWeight='bold'>{formatMessage(messages.numberOfPeople)}</MediumText>
			<InlineStack>
				<ButtonGroup size='md'>
					{buttonValues.map(buttonValue => (
						<Button
							id={`select-${buttonValue}-number-of-people`}
							key={buttonValue}
							onClick={() => onChange(buttonValue)}
							variantColor={value === buttonValue ? 'primary' : 'gray'}
							my={2}
						>
							{buttonValue}
						</Button>
					))}
					<AddMorePeopleButton variantColor='gray' onChange={onChange} />
				</ButtonGroup>
			</InlineStack>
		</Stack>
	)
}

PeopleNumberSelector.defaultProps = {
	defaultValues: []
}

PeopleNumberSelector.propTypes = {
	value: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired,
	defaultValues: PropTypes.arrayOf(PropTypes.number)
}

export default PeopleNumberSelector
