import React, { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Divider, Stack } from '@chakra-ui/core'

import { useHistory } from 'react-router'
import TicketCard from '../components/TicketCard'
import AppContainer from '../../ui/containers/AppContainer'
import Title from '../../ui/text/Title'
import { LargeText, MediumText } from '../../ui/text'
import messages from '../messages'
import NotificationsWidget from '../../notifications/NotificationsWidget'
import { addSWLocation, areBrowserNotificationsEnabled } from '../../notifications/pushNotifications'
import DismissButtonContainer from './DismissButtonContainer'
import api from '../../api/api'
import { interceptError } from '../../utils/errors/errorInterceptors'
import ClientID from '../../clientid/ClientID'
import CenteredLoading from '../../ui/loading/CenteredLoading'
import Sound from '../../notifications/Sound'
import notificationSound from '../../notifications/notification.wav'
import useQmState from '../useQMState'

const TicketContainer = ({
	match: {
		params: { locationId }
	}
}) => {
	const history = useHistory()
	const { formatMessage } = useIntl()

	const [ticket, setTicket] = useState(undefined)

	const [clientID, setClientID] = useState(undefined)
	const [isLoading, setLoading] = useState(true)
	const [isTicketBeingCalled, setTicketBeingCalled] = useState(false)

	const { calledTicket } = useQmState(locationId)

	const ticketsToGo = ticket?.sequential_number - (calledTicket ? calledTicket.sequential_number : -1)
	const alreadyCalled = ticketsToGo <= 0

	const fetchWaitingTicket = useCallback(
		clientId =>
			api.tickets
				.clientTicket(locationId, clientId)
				.then(({ data }) => {
					if (!data.id) return Promise.reject(data)

					setTicket(data)
					return Promise.resolve(data)
				})
				.catch(interceptError),
		[locationId]
	)

	useEffect(() => {
		ClientID.getID()
			.then(id => {
				setClientID(id)
				fetchWaitingTicket(id)
					.catch(() => history.replace('/'))
					.finally(() => setLoading(false))
			})
			.catch(() => setLoading(false))
	}, [fetchWaitingTicket, history])

	useEffect(() => {
		const location = ticket?.location
		if (location) {
			addSWLocation({
				id: location.id,
				name: location.name,
				notificationClickUrl: `${process.env.REACT_APP_QR_Q_PUBLIC_PATH}${location.id}/ticket`
			})
		}
	}, [ticket])

	useEffect(() => {
		if (ticketsToGo === 0) {
			setTicketBeingCalled(true)
			window.navigator && window.navigator.vibrate && window.navigator.vibrate([200, 100, 200])
		}
	}, [ticketsToGo, ticket, calledTicket])

	const ticketInfo = (
		<LargeText textAlign='center' fontWeight={alreadyCalled && 'bold'}>
			{alreadyCalled
				? formatMessage(messages.ticketWasCalled)
				: calledTicket
				? `${formatMessage(messages.currentTicket)} - ${calledTicket.formatted_number}`
				: undefined}
		</LargeText>
	)

	const ticketDescription = (
		<MediumText textAlign='center' fontWeight='medium'>
			{alreadyCalled ? `${formatMessage(messages.goToStore)} 🤗` : `${formatMessage(messages.keepEyeTicketWarning)} 👀`}
		</MediumText>
	)

	return isLoading ? (
		<CenteredLoading />
	) : (
		<>
			{isTicketBeingCalled && <Sound source={notificationSound} />}
			<AppContainer.Header>
				<Title textAlign='center'>{ticket.location.name}</Title>
			</AppContainer.Header>
			<Divider />
			<AppContainer.Content>
				<Stack spacing={5} align='center' h='100%'>
					<TicketCard ticket={ticket} isBlinking={alreadyCalled} />
					{ticketInfo}
					{ticketDescription}
					{!alreadyCalled ? (
						areBrowserNotificationsEnabled() && <NotificationsWidget clientId={clientID} />
					) : (
						<DismissButtonContainer clientId={clientID} locationId={ticket.location.id} ticketId={ticket.id} />
					)}
				</Stack>
			</AppContainer.Content>
		</>
	)
}

export default TicketContainer
