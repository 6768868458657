import { useEffect, useState } from 'react'
import { getTopic, matchTopic, useMQTT } from '../mqtt'
import ticketTopics from './topics'
import locationTopics from '../location/topics'

const useQmState = locationId => {
	const { subscribe, lastMessage } = useMQTT()

	const [calledTicket, setCalledTicket] = useState(undefined)
	const [lastReset, setLastReset] = useState(undefined)

	useEffect(() => {
		if (lastReset && calledTicket) {
			if (new Date(lastReset.created_at) > new Date(calledTicket.called_at)) {
				setCalledTicket(undefined)
			}
		}
	}, [lastReset, calledTicket])

	useEffect(() => {
		const calledTicketTopic = getTopic(ticketTopics.called, { locationId })
		const lastResetTopic = getTopic(locationTopics.reset, { locationId })
		const { unsubscribe: unsubscribeCalledTicket } = subscribe(calledTicketTopic)
		const { unsubscribe: unsubscribeLastReset } = subscribe(lastResetTopic)

		return () => {
			unsubscribeCalledTicket()
			unsubscribeLastReset()
		}
	}, [locationId, subscribe])

	useEffect(() => {
		const { topic, message } = lastMessage

		if (matchTopic(ticketTopics.called, topic)) {
			const { location: msgLocationId } = message
			if (Number(locationId) === msgLocationId) {
				setCalledTicket(message)
			}
		}
		if (matchTopic(locationTopics.reset, topic)) {
			setLastReset(message)
		}
	}, [lastMessage, locationId])

	return { lastReset, calledTicket }
}

export default useQmState
