import errorTypes from './errorTypes'

export const filterNoConnectionError = callback => error => {
	let currentError = error
	if (error.isAxiosError && !currentError.response) {
		callback()
		currentError = { response: { data: { status: -1 }, status: -1 } }
		return Promise.resolve(currentError)
	}
	return Promise.reject(currentError)
}

export const filter4XXError = callback => error => {
	if (error.isAxiosError) {
		const { status } = error.response.data
		if (status > 400 && status < 500 && status !== 404) {
			callback()
			return Promise.resolve(error)
		}
	}
	return Promise.reject(error)
}

export const filter5XXError = callback => error => {
	if (error.isAxiosError) {
		const { status } = error.response
		if (status >= 500) {
			callback()
			return Promise.resolve(error)
		}
	}
	return Promise.reject(error)
}

export const filter400GeneralError = callback => error => {
	if (error.isAxiosError) {
		const { title, status, type } = error.response.data
		if (status === 400 && type !== errorTypes.invalid) {
			callback(title)
			return Promise.resolve(error)
		}
	}
	return Promise.reject(error)
}

export const filter404Error = callback => error => {
	if (error.isAxiosError) {
		const { data, status, statusText } = error.response
		if (status === 404) {
			const { title } = data
			callback(title || statusText)
			return Promise.resolve(error)
		}
	}
	return Promise.reject(error)
}

export const filter400AttributeValidationError = callback => error => {
	if (error.isAxiosError) {
		const { detail, status, type } = error.response.data
		if (status === 400 && type === errorTypes.invalid && !detail.non_field_errors) {
			callback(detail)
			return Promise.resolve(error)
		}
	}
	return Promise.reject(error)
}

export const filter400ValidationNonFieldError = callback => error => {
	if (error.isAxiosError) {
		const { detail, status, type } = error.response.data
		if (status === 400 && type === errorTypes.invalid && detail.non_field_errors) {
			callback(detail.non_field_errors)
			return Promise.resolve(error)
		}
	}
	return Promise.reject(error)
}

export const filterInvalidQRCode = callback => error => {
	if (error.isAxiosError) {
		const { status } = error.response || {}
		if (status === 403 || status === 404) {
			callback()
			return Promise.resolve(error)
		}
	}
	return Promise.reject(error)
}
