import axios from 'axios'
import randomIPv6 from './randomIPv6'

const Ipify = {
	get: () => {
		const clientIP = localStorage.getItem('cip')
		return clientIP
			? Promise.resolve(clientIP)
			: axios({
					method: 'get',
					url: 'https://api6.ipify.org/'
			  })
					.then(({ data }) => {
						localStorage.setItem('cip', data)
						return Promise.resolve(data)
					})
					.catch(() => {
						const ip = randomIPv6()
						localStorage.setItem('cip', ip)
						return Promise.resolve(ip)
					})
	}
}

export default Ipify
