import React from 'react'

const Icons = {
	notifications: {
		path: (
			<g fill='currentColor'>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M4.307 1.932a5.4 5.4 0 0 1 9.218 3.818c0 2.675.572 4.347 1.108 5.33.269.492.532.818.718 1.014a2.527 2.527 0 0 0 .26.24l.006.004a.65.65 0 0 1-.367 1.187H1a.65.65 0 0 1-.367-1.187h.001l.002-.002m-.003.002l.006-.003.047-.038a2.53 2.53 0 0 0 .213-.203c.186-.196.45-.522.718-1.015.536-.982 1.108-2.654 1.108-5.329a5.4 5.4 0 0 1 1.582-3.818m3.818-.282a4.1 4.1 0 0 0-4.1 4.1c0 2.867-.615 4.757-1.267 5.952a7.11 7.11 0 0 1-.314.523h11.362a7.108 7.108 0 0 1-.314-.523c-.652-1.195-1.267-3.085-1.267-5.952a4.1 4.1 0 0 0-4.1-4.1zM.639 12.335H.637zM6.43 15.48a.65.65 0 0 1 .888.236.933.933 0 0 0 1.614 0 .65.65 0 1 1 1.125.652 2.233 2.233 0 0 1-3.864 0 .65.65 0 0 1 .236-.888z'
				/>
			</g>
		),
		viewBox: '0 0 16 18'
	},
	success: {
		path: (
			<g fill='currentColor'>
				<path d='M12.843 6.162a.503.503 0 0 0-.74 0L7.73 10.686l-1.834-1.88a.503.503 0 0 0-.74 0 .545.545 0 0 0 0 .766l2.204 2.262c.096.1.241.166.37.166a.545.545 0 0 0 .37-.166l4.743-4.89a.565.565 0 0 0 0-.782z' />
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M9 1.7a7.3 7.3 0 1 0 0 14.6A7.3 7.3 0 0 0 9 1.7zM.3 9a8.7 8.7 0 1 1 17.4 0A8.7 8.7 0 0 1 .3 9z'
				/>
			</g>
		),
		viewBox: '0 0 18 18'
	},
	close: {
		path: (
			<g fill='currentColor'>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M14.707.293a1 1 0 0 1 0 1.414l-13 13a1 1 0 0 1-1.414-1.414l13-13a1 1 0 0 1 1.414 0z'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M.293.293a1 1 0 0 1 1.414 0l13 13a1 1 0 0 1-1.414 1.414l-13-13a1 1 0 0 1 0-1.414z'
				/>
			</g>
		),
		viewBox: '0 0 15 15'
	},
	trash: {
		path: (
			<g fill='currentColor'>
				<path
					d='M14.991 2.3h-2.945a2.564 2.564 0 0 0-5.092 0H4.009a2.17 2.17 0 0 0-2.166 2.165v.114c0 .912.57
				1.691 1.368 2.008v10.222a2.17 2.17 0 0 0 2.166 2.166h8.252a2.17 2.17 0 0 0 2.166-2.166V6.587a2.164 2.164
				0 0 0 1.368-2.008v-.114A2.179 2.179 0 0 0 14.991 2.3zM9.5 1.057c.747 0 1.368.538 1.507
				1.241H7.993c.139-.703.76-1.241 1.507-1.241zm5.263 15.744a1.14 1.14 0 0 1-1.14 1.14H5.371c-.627
				0-1.14-.513-1.14-1.14V6.74h10.526v10.063h.006zm1.368-12.23c0 .628-.513 1.14-1.14 1.14H4.009c-.627
				0-1.14-.512-1.14-1.14V4.46c0-.627.513-1.14 1.14-1.14h10.988c.627 0 1.14.513 1.14 1.14v.114h-.006z'
				/>
				<path d='M6.751 16.632a.51.51 0 0 0 .513-.513v-5.776a.51.51 0 0 0-.513-.513.51.51 0 0 0-.513.513v5.776a.51.51 0 0 0 .513.513zM9.5 16.632a.51.51 0 0 0 .513-.513v-5.776A.51.51 0 0 0 9.5 9.83a.51.51 0 0 0-.513.513v5.776a.51.51 0 0 0 .513.513zM12.249 16.632a.51.51 0 0 0 .513-.513v-5.776a.51.51 0 0 0-.513-.513.51.51 0 0 0-.513.513v5.776c0 .285.234.513.513.513z' />
			</g>
		),
		viewBox: '0 0 19 19'
	},
	warning: {
		path: (
			<g fill='currentColor'>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M8.115.593a2.276 2.276 0 0 1 3.062.802l.002.004 6.678 11.15a2.276 2.276 0 0 1-1.945 3.413H2.55a2.276 2.276 0 0 1-1.946-3.414l.006-.01 6.672-11.14.601.36-.599-.363c.203-.334.489-.61.83-.802zm.368 1.527v.002l-6.668 11.13a.875.875 0 0 0 .748 1.31H15.9a.876.876 0 0 0 .747-1.31L9.98 2.122l-.001-.002a.876.876 0 0 0-1.496 0z'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M9.231 5.108a.7.7 0 0 1 .7.7v3.151a.7.7 0 1 1-1.4 0V5.808a.7.7 0 0 1 .7-.7zM8.531 12.11a.7.7 0 0 1 .7-.7h.007a.7.7 0 0 1 0 1.4h-.007a.7.7 0 0 1-.7-.7z'
				/>
			</g>
		),
		viewBox: '0 0 19 16'
	},
	ticket: {
		path: (
			<g fill='currentColor'>
				<path d='M15.056 5.296l-.88-.896s-.016 0-.016.016a1.816 1.816 0 0 1-2.56 0 1.816 1.816 0 0 1 0-2.56s0-.016.016-.016L10.72.96a.57.57 0 0 0-.784 0L7.488 3.424l.768.768-.592.576L6.896 4 .96 9.92a.523.523 0 0 0 0 .768l4.32 4.352a.55.55 0 0 0 .768 0L12 9.12l-.752-.768.576-.592.752.768 2.48-2.448a.57.57 0 0 0 0-.784zm-6.8.032l.592-.576.992 1.008-.592.576-.992-1.008zm2.448 2.48l-.928-.928.592-.576.912.928-.576.576z' />
			</g>
		),
		viewBox: '0 0 16 16'
	},
	person: {
		path: (
			<g fill='currentColor'>
				<path d='M6.576 1.504c0 .784.64 1.424 1.424 1.424.784 0 1.424-.64 1.424-1.424C9.424.72 8.784.08 8 .08c-.784 0-1.424.64-1.424 1.424zM7.072 3.168h1.856c1.024 0 1.856.832 1.856 1.856v4.032H9.92V5.664h-.128V9.76l-.56 6.16H8.128v-5.824h-.256v5.824H6.768l-.56-6.16V5.664H6.08v3.392h-.864V5.024c0-1.024.832-1.856 1.856-1.856z' />
			</g>
		),
		viewBox: '0 0 16 16'
	},
}

export default Icons
