import React from 'react'
import { Flex, Box } from '@chakra-ui/core'

const AppContainer = props => <Flex {...props} p={2} maxW='xs' mx='auto' flexDirection='column' />

AppContainer.Header = props => <Box {...props} my={4} />
AppContainer.Content = props => <Box {...props} my={4} />
AppContainer.Footer = props => <Box {...props} my={2} />

export default AppContainer
