import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Stack } from '@chakra-ui/core'
import { useHistory } from 'react-router'

import api from '../../api/api'
import { getTopic, matchTopic, useMQTT } from '../../mqtt'
import { LargeText, MediumText } from '../../ui/text'
import Button from '../../buttons/Button'
import messages from '../messages'
import { interceptError } from '../../utils/errors/errorInterceptors'
import ticketTopics from '../topics'
import PeopleNumberSelector from '../components/PeopleNumberSelector'

const GenerateTicketContainer = ({ locationId, clientId, signature, intl: { formatMessage } }) => {
	const [isButtonLoading, setButtonLoading] = useState(false)
	const [waitingTickets, setWaitingTickets] = useState(undefined)
	const [numberOfPeople, setNumberOfPeople] = useState(1)
	const history = useHistory()
	const { subscribe, lastMessage } = useMQTT()

	useEffect(() => {
		const waitingTicketsTopic = getTopic(ticketTopics.waiting, { locationId })
		const { unsubscribe } = subscribe(waitingTicketsTopic)

		return () => unsubscribe()
	}, [locationId, subscribe])

	useEffect(() => {
		const { topic, message } = lastMessage
		if (matchTopic(ticketTopics.waiting, topic)) {
			const { waiting } = message
			setWaitingTickets(waiting)
		}
	}, [setWaitingTickets, lastMessage])

	const generateTicket = () => {
		setButtonLoading(true)
		api.tickets
			.generate(locationId, signature, clientId, numberOfPeople)
			.then(() => history.replace(`/${locationId}/ticket`))
			.catch(error => {
				setButtonLoading(false)
				interceptError(error)
			})
	}

	const hasWaitingTicketsInfo = waitingTickets !== undefined
	return (
		<Stack h='100%' align='center' justify='center' spacing={4} my={16}>
			<PeopleNumberSelector onChange={setNumberOfPeople} value={numberOfPeople} defaultValues={[1, 2, 3, 4]} />
			<Button mx='auto' isLoading={isButtonLoading} onClick={generateTicket}>
				<LargeText>{formatMessage(messages.generateTicket)}</LargeText>
			</Button>
			{hasWaitingTicketsInfo && (
				<MediumText textAlign='center'>{formatMessage(messages.nTicketsAhead, { number: waitingTickets })}</MediumText>
			)}
		</Stack>
	)
}

GenerateTicketContainer.propTypes = {
	locationId: PropTypes.string.isRequired,
	clientId: PropTypes.string.isRequired,
	signature: PropTypes.string.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
}

export default injectIntl(GenerateTicketContainer)
