import Fingerprint2 from 'fingerprintjs2'
import Ipify from './Ipify'

export const fingerPrinting = () =>
	new Promise(resolve => {
		const excludes = {
			adBlock: true,
			enumerateDevices: true,
			pixelRatio: true,
			doNotTrack: true,
			fontsFlash: true,
			canvas: true,
			hasLiedLanguages: true,
			hasLiedResolution: true,
			hasLiedOs: true,
			hasLiedBrowser: true,
			language: true
		}

		const joinValues = components => components.map(component => component.value).join('')

		const hashConstructor = components => {
			resolve({ hash: Fingerprint2.x64hash128(joinValues(components), 31) })
		}

		const ipComponent = {
			key: 'ip',
			getData: done =>
				Ipify.get().then(ip => {
					done(ip)
				})
		}

		const getFringerprint = () => {
			Fingerprint2.get({ excludes, extraComponents: [ipComponent] }, hashConstructor)
		}

		if (window.requestIdleCallback) {
			requestIdleCallback(getFringerprint)
		} else {
			setTimeout(getFringerprint, 500)
		}
	})
