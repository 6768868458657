import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { Button, Flex, Divider, Icon } from '@chakra-ui/core'
import messages from '../messages'
import { SmallText } from '../../ui/text'
import { useFormatTime, useFormatDate } from '../dateAndTimeFormatters'

const PreviousTicket = ({ ticket, onClick }) => {
	const { formatMessage } = useIntl()
	return (
		<Flex flexDirection='column' alignItems='center' justifyContent='center'>
			<SmallText my={1}>{formatMessage(messages.recoverTicket)}</SmallText>
			<Button fontWeight='normal' my={2} onClick={onClick}>
				<Flex>
					<Icon name='ticket' color='black' mr={1} />
					<span>{ticket.formatted_number}</span>
					<Divider orientation='vertical' borderColor='brand.gray' />
					{useFormatDate(ticket.created_at)}
					<Divider orientation='vertical' borderColor='brand.gray' />
					{useFormatTime(ticket.created_at)}
				</Flex>
			</Button>
		</Flex>
	)
}

PreviousTicket.propTypes = {
	onClick: PropTypes.func.isRequired,
	ticket: PropTypes.shape({
		created_at: PropTypes.string.isRequired,
		formatted_number: PropTypes.string.isRequired
	}).isRequired
}

export default PreviousTicket
