import { compile, pathToRegexp } from 'path-to-regexp'

export const getTopic = (topic, params) => {
	const toPath = compile(topic, { encode: encodeURIComponent })
	return decodeURIComponent(toPath(params))
}

export const getTopicParams = (topic, messageTopic) => pathToRegexp(topic).exec(messageTopic)

export const matchTopic = (topic, messageTopic) => !!getTopicParams(topic, messageTopic)
