import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { registerServiceWorker } from './notifications/pushNotifications'
import { IntlProvider } from 'react-intl'
import { intlProps } from './locales/LocaleData'
import { Router } from 'react-router-dom'
import { CSSReset, ThemeProvider } from '@chakra-ui/core'
import theme from './theme/theme'
import { createBrowserHistory } from 'history'

const history = createBrowserHistory()

ReactDOM.render(
	<IntlProvider {...intlProps}>
		<Router history={history}>
			<ThemeProvider theme={theme}>
				<CSSReset />
				<App />
			</ThemeProvider>
		</Router>
	</IntlProvider>,
	document.getElementById('root')
)

registerServiceWorker()
