import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router'
import { Divider } from '@chakra-ui/core'
import api from '../api/api'
import ClientID from '../clientid/ClientID'
import { interceptError, interceptInvalidQR } from '../utils/errors/errorInterceptors'
import GenerateTicketContainer from '../ticket/containers/GenerateTicketContainer'
import PreviousTicket from '../ticket/components/PreviousTicket'
import { Title } from '../ui/text'
import AppContainer from '../ui/containers/AppContainer'
import CenteredLoading from '../ui/loading/CenteredLoading'
import NotFound from '../notFound/NotFound'

const TICKET_WAITING = 1

const LocationContainer = ({
	match: {
		params: { locationId, hash }
	}
}) => {
	const history = useHistory()
	const [location, setLocation] = useState(undefined)
	const [clientID, setClientID] = useState(undefined)
	const [isQRCodeInvalid, setQRCodeInvalid] = useState(undefined)
	const [isLoading, setLoading] = useState(true)
	const [previousTicket, setPreviousTicket] = useState()

	const signature = `${locationId}:${hash}`
	const goToTicket = useCallback(() => history.replace(`/${locationId}/ticket`), [locationId, history])

	const fetchLocation = useCallback(
		() =>
			api.locations
				.detail(locationId, signature)
				.then(({ data }) => {
					setLocation(data)
				})
				.catch(error => interceptInvalidQR(error, () => setQRCodeInvalid(true)))
				.catch(interceptError),
		[locationId, signature]
	)

	const fetchWaitingTicket = useCallback(
		clientId =>
			api.tickets
				.clientTicket(locationId, clientId)
				.then(({ data }) => {
					if (!data.id) return Promise.reject(data)
					if (data.state === TICKET_WAITING) {
						goToTicket()
					} else {
						setPreviousTicket(data)
					}
					return Promise.resolve(data)
				})
				.catch(interceptError),
		[locationId, goToTicket]
	)

	useEffect(() => {
		ClientID.getID()
			.then(id => {
				setClientID(id)
				fetchLocation()
					.then(() => fetchWaitingTicket(id))
					.finally(() => setLoading(false))
			})
			.catch(() => setLoading(false))
	}, [signature, fetchLocation, fetchWaitingTicket])

	if (isQRCodeInvalid) {
		return <NotFound />
	}

	return isLoading ? (
		<CenteredLoading />
	) : location ? (
		<>
			<AppContainer.Header>
				<Title textAlign='center'>{location.name}</Title>
			</AppContainer.Header>
			<Divider />
			<AppContainer.Content>
				<GenerateTicketContainer locationId={locationId} clientId={clientID} signature={signature} />
				{previousTicket && <PreviousTicket ticket={previousTicket} onClick={goToTicket} />}
			</AppContainer.Content>
		</>
	) : null
}

LocationContainer.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({ locationId: PropTypes.string.isRequired, hash: PropTypes.string.isRequired }).isRequired
	}).isRequired
}

export default LocationContainer
