import { defineMessages } from 'react-intl'

export const scope = 'qrq.footer'

const messages = defineMessages({
	feedback: {
		id: `${scope}.feedback`
	}
})

export default messages
