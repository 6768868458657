import { defineMessages } from 'react-intl'

export const scope = 'qrq.ticket'

const messages = defineMessages({
	ticketWasCalled: {
		id: `${scope}.ticketWasCalled`
	},
	keepEyeTicketWarning: {
		id: `${scope}.keepEyeTicketWarning`
	},
	goToStore: {
		id: `${scope}.goToStore`
	},
	currentTicket: {
		id: `${scope}.currentTicket`
	},
	dismissTicket: {
		id: `${scope}.dismissTicket`,
		defaultMessage: 'Dismiss ticket'
	},
	confirmDismissTicket: {
		id: `${scope}.confirmDismissTicket`
	},
	generateTicket: {
		id: `${scope}.generateTicket`
	},
	nTicketsAhead: {
		id: `${scope}.nTicketsAhead`
	},
	recoverTicket: {
		id: `${scope}.recoverTicket`
	},
	numberOfPeople: {
		id: `${scope}.numberOfPeople`
	},
	peopleNumberMore: {
		id: `${scope}.peopleNumberMore`
	},
	peopleNumberPlaceholder: {
		id: `${scope}.peopleNumberPlaceholder`
	}
})

export default messages
