import React from 'react'
import PropTypes from 'prop-types'
import { Divider, Flex, Icon } from '@chakra-ui/core'
import { keyframes } from '@emotion/core'
import styled from '@emotion/styled'
import { HugeText, MediumText } from '../../ui/text'
import { useFormatDate, useFormatTime } from '../dateAndTimeFormatters'
import theme from '../../theme/theme'

const TicketCard = ({ ticket, isBlinking, ...props }) => {
	const color = isBlinking ? 'white' : 'black'
	const borderWidth = isBlinking ? '0' : '5px'

	const ticketDate = new Date(ticket.created_at)
	const formattedDate = useFormatDate(ticketDate)
	const formattedTime = useFormatTime(ticketDate)

	return (
		<FlexWithAnimation
			{...props}
			direction='column'
			justify='center'
			w='3xs'
			h='3xs'
			py={4}
			borderWidth={borderWidth}
			borderColor='primary.500'
			rounded='lg'
			textAlign='center'
			isBlinking={isBlinking}
		>
			<HugeText color={color} my='auto'>
				{ticket.formatted_number}
			</HugeText>
			<Flex justify='center' align='center'>
				<MediumText color={color}>{formattedDate}</MediumText>
				<Divider h='100%' orientation='vertical' borderColor={color} />
				<MediumText color={color}>{formattedTime}</MediumText>
				<Divider h='100%' orientation='vertical' borderColor={color} />
				<Icon name='person' color={color} size='5' />
				<MediumText color={color}>{ticket.number_of_people}</MediumText>
			</Flex>
		</FlexWithAnimation>
	)
}
const animation = keyframes`
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
`
const FlexWithAnimation = styled(Flex)`
	animation: ${animation} 1s ease infinite;
	background: ${({ isBlinking }) =>
		isBlinking ? `linear-gradient(270deg, ${theme.colors.primary[600]}, ${theme.colors.primary[900]})` : 'white'};
	background-size: 400%;
`
TicketCard.propTypes = {
	isBlinking: PropTypes.bool.isRequired,
	ticket: PropTypes.shape({
		formatted_number: PropTypes.string.isRequired,
		created_at: PropTypes.string.isRequired,
		number_of_people: PropTypes.number.isRequired
	}).isRequired
}

export default TicketCard
