import swMessageTypes from './swMessagesTypes'

const DeviceNotSupportedError = new Error('Push Notifications are not supported on this device.')
const NotificationConsentNotGrantedError = new Error('Notification consent was not granted')
export const NotificationConsentDeniedError = new Error('Notification consent was denied')

const pushNotificationSupported =
	'serviceWorker' in navigator && 'PushManager' in window && 'Notification' in window && 'indexedDB' in window

export const areBrowserNotificationsEnabled = () => pushNotificationSupported && Notification.permission !== 'denied'

export const registerServiceWorker = () =>
	pushNotificationSupported &&
	navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/qrq-service-worker.js`).then(sw => sw.update())

export const requestNotificationPermission = () =>
	pushNotificationSupported
		? Notification.requestPermission().then(consent =>
				consent === 'granted'
					? Promise.resolve()
					: consent === 'denied'
					? Promise.reject(NotificationConsentDeniedError)
					: Promise.reject(NotificationConsentNotGrantedError)
		  )
		: Promise.reject(DeviceNotSupportedError)

export const hasUserConsented = () => pushNotificationSupported && Notification.permission === 'granted'

const sendSWMessage = (payload, transfer) =>
	pushNotificationSupported &&
	navigator.serviceWorker.ready.then(sw => sw.active && sw.active.postMessage(JSON.stringify(payload), transfer))

export const addSWLocation = location => sendSWMessage({ type: swMessageTypes.SET_LOCATION, payload: location })

const createSWSubscription = () =>
	navigator.serviceWorker.ready.then(
		async sw =>
			(await sw.pushManager.getSubscription()) ||
			(await sw.pushManager.subscribe({
				userVisibleOnly: true,
				applicationServerKey: new Uint8Array(
					process.env.REACT_APP_NOTIFICATIONS_ARRAY_BUFFER_PUBLIC_KEY.split(',').map(e => parseInt(e, 10))
				)
			}))
	)

const createNotificationSubscription = () =>
	hasUserConsented() ? createSWSubscription() : Promise.reject(NotificationConsentNotGrantedError)

export const turnOnNotifications = () => {
	if (pushNotificationSupported) {
		return createNotificationSubscription()
	}
	return Promise.reject(DeviceNotSupportedError)
}
