import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@chakra-ui/core'

const states = ['on', 'off', 'away']

const BulletState = ({ state, ...props }) => (
	<Box as='span' w='6px' h='6px' borderRadius='50%' bg={`bulletState.${state}`} {...props} />
)

BulletState.propTypes = {
	state: PropTypes.oneOf(states).isRequired
}

BulletState.displayName = 'BulletState'

export default BulletState
