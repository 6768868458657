import {
	filterNoConnectionError,
	filter4XXError,
	filter5XXError,
	filter400GeneralError,
	filter404Error,
	filter400AttributeValidationError,
	filter400ValidationNonFieldError,
	filterInvalidQRCode
} from './errorFilters'
import { handleActionValidationError } from './errorHandlers'
import { showErrorModal, formatMessage } from './showUIErrors'
import messages from './messages'

export const interceptBaseError = error =>
	filterNoConnectionError(() => showErrorModal(formatMessage(messages.cantConnectServer)))(error)
		.catch(filter4XXError(() => showErrorModal(formatMessage(messages.errorDefaultMessage))))
		.catch(filter5XXError(() => showErrorModal(formatMessage(messages.serverError))))
		.catch(filter400GeneralError(message => showErrorModal(message)))
		.catch(filter404Error(message => showErrorModal(message)))

export const interceptError = error =>
	interceptBaseError(error)
		.catch(filter400AttributeValidationError(handleActionValidationError))
		.catch(filter400ValidationNonFieldError(errors => showErrorModal(errors.non_field_errors)))

export const interceptInvalidQR = (error, handler) => {
	filterInvalidQRCode(handler)(error)
}
