import { useEffect } from 'react'

const Sound = ({ source }) => {
	useEffect(() => {
		const audio = new Audio(source)
		audio.onloadeddata = () => audio.play()
		audio.load()
		return () => audio.pause()
	}, [source])

	return null
}

export default Sound
