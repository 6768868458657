import React from 'react'
import { PropTypes } from 'prop-types'
import { Divider, Image, Stack, Text } from '@chakra-ui/core'
import { injectIntl } from 'react-intl'
import AppContainer from '../ui/containers/AppContainer'
import { Title } from '../ui/text'
import messages from './messages'

const NotFound = ({ intl: { formatMessage } }) => {
	return (
		<>
			<AppContainer.Header>
				<Title textAlign='center'>{formatMessage(messages.oops)}</Title>
			</AppContainer.Header>
			<Divider />
			<AppContainer.Content>
				<Stack h='100%' align='center' justify='center' spacing={4}>
					<Image src='/images/not-found-qr.svg' alt='Read QR-Code' ignoreFallback={true} />
					<Text>{formatMessage(messages.notFoundDescription)}</Text>
				</Stack>
			</AppContainer.Content>
		</>
	)
}

NotFound.propTypes = {
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
}

export default injectIntl(NotFound)
