import React, { useContext, useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import mqtt from 'mqtt'

export const MQTTContext = React.createContext({})
export const useMQTT = () => useContext(MQTTContext)

const MQTTProvider = ({ connection, onConnectionError, children }) => {
	const [client, setClient] = useState(undefined)
	const [lastMessage, setLastMessage] = useState({})
	const [subscriptions, setSubscriptions] = useState({})

	useEffect(() => {
		const cl = mqtt.connect(connection)
		cl.stream.on('error', onConnectionError)
		cl.on('message', (topic, message, packet) => {
			setLastMessage({ topic, message: JSON.parse(message), packet })
		})
		setClient(cl)
	}, [connection, onConnectionError])

	const subscribe = useCallback(
		topic => {
			if (client) {
				client.subscribe(topic)
				setSubscriptions(prevSubscriptions => ({ ...prevSubscriptions, [topic]: (prevSubscriptions[topic] || 0) + 1 }))
			}

			return {
				unsubscribe: () =>
					setSubscriptions(prevSubscriptions => ({ ...prevSubscriptions, [topic]: prevSubscriptions[topic] - 1 }))
			}
		},
		[client]
	)

	useEffect(() => {
		Object.keys(subscriptions).forEach(topic => {
			if (subscriptions[topic] === 0) {
				client.unsubscribe(topic)
			}
		})
	}, [client, subscriptions])

	return (
		<MQTTContext.Provider
			value={{
				subscribe,
				lastMessage
			}}
		>
			{children}
		</MQTTContext.Provider>
	)
}

MQTTProvider.defaultProps = {
	children: null
}

MQTTProvider.propTypes = {
	connection: PropTypes.PropTypes.shape({}).isRequired,
	onConnectionError: PropTypes.func.isRequired,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
}

export default MQTTProvider
