import { useIntl } from 'react-intl'

export const useFormatDate = date => {
	const { formatDate } = useIntl()
	const options = { month: 'short', day: 'numeric' }
	return formatDate(date, options)
}

export const useFormatTime = date => {
	const { formatTime } = useIntl()
	return formatTime(date)
}
