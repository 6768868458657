import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { Button } from '@chakra-ui/core'
import { useHistory } from 'react-router'
import messages from '../messages'
import api from '../../api/api'
import { interceptError } from '../../utils/errors/errorInterceptors'
import useConfirm from '../../utils/confirm/useConfirm'

const DismissButtonContainer = ({ ticketId, clientId, locationId }) => {
	const { formatMessage } = useIntl()
	const history = useHistory()
	const confirm = useConfirm()

	const dismissHandler = () =>
		confirm({ title: formatMessage(messages.confirmDismissTicket) })
			.then(() =>
				api.tickets
					.dismiss(locationId, ticketId, clientId)
					.then(() => {
						history.replace('/')
					})
					.catch(interceptError)
			)
			.catch(() => {})

	return (
		<Button fontWeight='normal' variantColor='gray' onClick={dismissHandler} leftIcon='trash' textTransform='uppercase'>
			{formatMessage(messages.dismissTicket)}
		</Button>
	)
}

DismissButtonContainer.propTypes = {
	ticketId: PropTypes.number.isRequired,
	clientId: PropTypes.string.isRequired,
	locationId: PropTypes.number.isRequired
}

export default DismissButtonContainer
