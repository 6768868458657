import { defineMessages } from 'react-intl'

export const scope = 'qrq.notFound'

const messages = defineMessages({
	oops: {
		id: `${scope}.oops`
	},
	notFoundDescription: {
		id: `${scope}.notFoundDescription`
	}
})

export default messages
