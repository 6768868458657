import { qAxios } from './config'

export default {
	locations: {
		detail: (id, signature) => qAxios.get(`/public/locations/${id}/?signed_id=${signature}`)
	},
	tickets: {
		clientTicket: (locationId, clientId) =>
			qAxios.get(`/public/locations/${locationId}/tickets/client-ticket/?client_id=${clientId}`),
		generate: (locationId, signature, clientId, numberOfPeople) =>
			qAxios.post(`/public/locations/${locationId}/tickets/generate/?signed_id=${signature}`, {
				client_id: clientId,
				number_of_people: numberOfPeople
			}),
		dismiss: (locationId, ticketId, clientId) =>
			qAxios.put(`/public/locations/${locationId}/tickets/${ticketId}/dismiss/?client_id=${clientId}`)
	},
	notifications: {
		subscribe: (clientId, subscription) =>
			qAxios.post('/public/push-subscriptions/', { client_id: clientId, subscription })
	}
}
