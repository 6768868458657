import { fingerPrinting } from './fingerPrinting'

class ClientID {
	constructor() {
		this.id = null
	}

	getID = () =>
		this.id
			? Promise.resolve(this.id)
			: fingerPrinting().then(({ hash }) => {
					this.id = hash
					return Promise.resolve(this.id)
			  })
}

const instance = new ClientID()

export default instance
