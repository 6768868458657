import React, { useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import ConfirmContext from './ConfirmContext'
import ConfirmationModal from '../modals/components/ConfirmationModal'
import modalMessages from '../modals/messages'

const defaultOptions = {
	title: '',
	description: <FormattedMessage {...modalMessages.deleteMessage} />,
	confirmationText: <FormattedMessage {...modalMessages.yes} />,
	cancellationText: <FormattedMessage {...modalMessages.no} />,
	confirmationButtonProps: {},
	cancellationButtonProps: {}
}

export const ConfirmProvider = ({ children }) => {
	const [options, setOptions] = useState(defaultOptions)
	const [[resolve, reject], setResolveReject] = useState([])
	const isOpen = !!resolve || !!reject

	const confirm = useCallback((options = {}) => {
		return new Promise((resolve, reject) => {
			setOptions({ ...defaultOptions, ...options })
			setResolveReject([resolve, reject])
		})
	}, [])

	const handleClose = useCallback(() => {
		setResolveReject([])
	}, [])

	const handleCancel = useCallback(() => {
		reject()
		handleClose()
	}, [reject, handleClose])

	const handleConfirm = useCallback(() => {
		resolve()
		handleClose()
	}, [resolve, handleClose])

	return (
		<>
			<ConfirmContext.Provider value={confirm}>{children}</ConfirmContext.Provider>
			<ConfirmationModal {...options} isOpen={isOpen} onClose={handleCancel} onConfirm={handleConfirm} />
		</>
	)
}
